<template>
  <div>
    <div class="my-1 w-100 d-flex justify-content-start">
      <b-button
        v-if="loadStatus !== 'Completed'"
        variant="success"
        size="sm"
        @click="addHandle()"
      >
        Add +
      </b-button>
    </div>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :items="items"
      :fields="fields"
    >
        
      <!-- column width set -->
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ 'min-width': field.key === 'driver' || field.key === 'facility' || field.key === 'truck' || field.key === 'trailer' || field.key === 'origin' || field.key === 'destination' || field.key === 'plan_start' || field.key === 'plan_end' || field.key === 'actual_start' || field.key === 'actual_end' ? '200px' : '30px', 'width': field.key === 'action' ? '30px' : '' }"
        >
      </template>

      <!-- plan start time -->
      <template #cell(plan_start)="row">
        <flat-pickr
          v-model="row.item.plan_start"
          class="form-control"
          :config="{ enableTime: true }"
        />
      </template>

      <!-- plan end time -->
      <template #cell(plan_end)="row">
        <flat-pickr
          v-model="row.item.plan_end"
          class="form-control"
          :config="{ enableTime: true }"
        />
      </template>

      <!-- actual start time -->
      <template #cell(actual_start)="row">
        <flat-pickr
          v-model="row.item.actual_start"
          class="form-control"
          :config="{ enableTime: true }"
        />
      </template>

      <!-- actual end time -->
      <template #cell(actual_end)="row">
        <flat-pickr
          v-model="row.item.actual_end"
          class="form-control"
          :config="{ enableTime: true }"
        />
      </template>

      <!-- status -->
      <template #cell(status)="row">
        <b-badge
          :variant="row.item.status === 'Booked' ? 'warning' : row.item.status === 'Ready to Dispatch' ? 'info' : 'success'"
          class="px-1 py-1"
        >
          {{ row.item.status }}
        </b-badge>
      </template>

      <!-- type -->
      <template #cell(type)="row">
        <b-badge
          :variant="row.item.type === 'pickup' ? 'danger' : row.item.type === 'delivery' ? 'success' : row.item.type === 'Loading' ? 'warning' : 'primary'"
          class="px-1 py-1"
        >
          {{ row.item.type }}
        </b-badge>
      </template>
        
      <!-- driver -->
      <template #cell(driver)="row">
        <v-select
          id="mc-driver"
          v-model="row.item.driver"
          :options="drivers.drivers"
          label="name"
          :reduce="driver => driver.id"
          placeholder="Driver"
          class="w-100"
          append-to-body
          :calculate-position="withPopper"
        >
          <template
            #option="{ id, name }"
            :index="id"
          >
            {{ name }}
          </template>
        </v-select>
      </template>

      <!-- facility -->
      <template #cell(facility)="row">
        <v-select
          id="mc-facility"
          v-model="row.item.facility"
          :options="facilities.facilities"
          label="name"
          :reduce="facility => facility.name"
          placeholder="Facility"
          class="w-100"
          append-to-body
          :calculate-position="withPopper"
        >
          <template
            #option="{ id, name }"
            :index="id"
          >
            {{ name }}
          </template>
        </v-select>
      </template>

      <!-- truck -->
      <template #cell(truck)="row">
        <v-select
          id="mc-truck"
          v-model="row.item.truck"
          :options="trucks.trucks"
          label="tag"
          :reduce="truck => truck.tag"
          placeholder="Truck"
          class="w-100"
          append-to-body
          :calculate-position="withPopper"
        >
          <template
            #option="{ id, tag }"
            :index="id"
          >
            {{ tag }}
          </template>
        </v-select>
      </template>

      <!-- trailer -->
      <template #cell(trailer)="row">
        <v-select
          id="mc-trailer"
          v-model="row.item.trailer"
          :options="trailers.trailers"
          label="tag"
          :reduce="trailer => trailer.tag"
          placeholder="Trailer"
          class="w-100"
          append-to-body
          :calculate-position="withPopper"
        >
          <template
            #option="{ id, tag }"
            :index="id"
          >
            {{ tag }}
          </template>
        </v-select>
      </template>

      <!-- origin -->
      <template #cell(origin)="row">
        <b-form-textarea
          v-model="row.item.origin"
        />
      </template>

      <!-- destination -->
      <template #cell(destination)="row">
        <b-form-textarea
          v-model="row.item.destination"
        />
      </template>

      <template #cell(action)="row">
        <b-button
          v-if="row.item.type === 'pickup' || row.item.type === 'delivery'"
          variant="primary"
          :disabled="row.item.status !== 'Booked'"
          @click="assign(row.item)"
        >
          Assign
        </b-button>
      </template>

    </b-table>
  </div>
</template>

<script>
import {
  BTable, BBadge, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { createPopper } from '@popperjs/core'
import moment from 'moment'
import * as loadService from '@/services/load'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
// import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BTable,
    BBadge,
    BButton,
    // BFormInput,
    BFormTextarea,
    vSelect,
    // FeatherIcon,
    flatPickr,
  },
  filters: {
    moment(date) {
      return moment(date).format('MM/DD/yyyy HH:mm')
    },
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loadStatus: {
      type: String,
      default: '',
    },
  },
  inject: ['drivers', 'facilities', 'trucks', 'trailers'],
  data() {
    return {
      weight: 0,
      pallets: 0,
      cases: 0,
      spots: 0,
      DocumentIcon: require('@/assets/images/upload/document.png'),
      ImageIcon: require('@/assets/images/upload/image.png'),
      fields: [
        { key: 'type', label: 'Action Type', sortable: true },
        { key: 'status', label: 'Status' },
        { key: 'plan_start', label: 'Plan Start Time', sortable: true },
        { key: 'plan_end', label: 'Plan End Time', sortable: true },
        { key: 'driver', label: 'Driver', sortable: true },
        { key: 'truck', label: 'Truck #', sortable: true },
        { key: 'trailer', label: 'Trailer #', sortable: true },
        { key: 'origin', label: 'From', sortable: true },
        { key: 'destination', label: 'To', sortable: true },
        { key: 'actual_start', label: 'Actual Start Time', sortable: true },
        { key: 'actual_end', label: 'Actual End Time', sortable: true },
        { key: 'facility', label: 'Facility', sortable: true },
        { key: 'action', label: 'Action' },
      ],
      placement: 'top',
    }
  },
  methods: {
    // assign operation to driver
    assign(item) {
      if (item.status !== 'Booked') {
        return
      }

      const data = {
        id: item.id,
        plan_start: item.plan_start,
        plan_end: item.plan_end,
        type: item.type,
        driver: item.driver,
        facility: item.facility,
        truck: item.truck,
        trailer: item.trailer,
        origin: item.origin,
        destination: item.destination,
      }

      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, submit it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          loadService.assign(data)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              const foundIndex = this.items.findIndex(x => x.id === item.id)
              this.items[foundIndex].status = 'Ready to Dispatch'
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.response.data,
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
            })
        }
      })
    },

    // create new operation
    addHandle() {
      this.items.push({
        status: 'Booked',
        plan_start: null,
        plan_end: null,
        actual_start: null,
        actual_end: null,
        type: '',
        driver: null,
        facility: null,
        truck: null,
        trailer: null,
        origin: '',
        destination: '',
      })
    },

    // date format function
    format(date) {
      return moment(date).format('MM/DD/yyyy HH:mm')
    },

    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
