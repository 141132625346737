<template>
  <b-card>
    <b-row class="px-1 pt-1">

      <b-col
        v-if="item.load.acc_status !=='Finalized'"
        md="12"
        class="mb-1 d-flex justify-content-start"
      >
        <feather-icon
          class="mr-1"
          size="18"
          :icon="`${isEdit ? 'RefreshCcw' : 'Edit2'}Icon`"
          @click="editMode"
        />
      
        <feather-icon
          v-if="isEdit"
          icon="SaveIcon"
          size="18"
          @click="save"
        />
      </b-col>
      
      <b-col md="3">
        <b-row>
          <b-col
            md="12"
            class="mb-1"
          >
            <strong>
              <b-badge
                :variant="item.load.acc_status === 'Finished' ? 'success' : item.load.acc_status === 'Booked' ? 'warning' : 'info'"
              >
                {{ item.load.acc_status }}
              </b-badge>
              /QB # : {{ item.load.invoiceID }}
            </strong>
          </b-col>

          <b-col
            md="12"
            class="mb-1"
          >
            <strong>Carrier Account Name : {{ item.load.company_name }}</strong>
          </b-col>

          <b-col
            md="12"
            class="mb-1"
          >
            <strong>Dispatcher Name : {{ `${item.load.dispatcher_firstname} ${item.load.dispatcher_lastname}` }}</strong>
          </b-col>

          <b-col
            md="12"
            class="mb-1"
          >
            <strong>Carrier Generated # : {{ item.load.uuid }}</strong>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="3">
        <b-row>
          <b-col
            md="12"
            class="mb-1"
          >
            <strong>Customer Name : {{ item.load.customer_name }}</strong>
          </b-col>

          <b-col
            md="12"
            class="mb-1"
          >
            <strong>Rep Name : {{ item.load.customer_rep }}</strong>
          </b-col>

          <b-col
            md="12"
            class="mb-1"
          >
            <strong>Rep Contact Email : {{ item.load.customer_rep_email }}</strong>
          </b-col>

          <b-col
            md="12"
            class="mb-1"
          >
            <strong>Rep Contact Phone : {{ item.load.customer_rep_phone }}</strong>
          </b-col>
          
          <b-col
            md="12"
            class="mb-1"
          >
            <strong>Customer Load ID # : {{ item.load.loadID }}</strong>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            <strong>Rate Con Price :
              <b-form-input
                v-model.number="item.load.rate_confirmation_amount"
                type="number"
                :disabled="!isEdit"
                placeholder="Rate Con Price"
                @input="changePrice"
              />
            </strong>
          </b-col>
          
          <b-col
            md="6"
            class="mb-1"
          >
            <strong>Tonu :
              <b-form-input
                v-model.number="item.load.tonu"
                type="number"
                :disabled="!isEdit"
                placeholder="Tonu"
                @input="changePrice"
              />
            </strong>
          </b-col>

          <b-col
            md="6"
            class="mb-1"
          >
            <strong>Detention Charges :
              <b-form-input
                v-model.number="item.load.detention"
                type="number"
                :disabled="!isEdit"
                placeholder="Detention Charges"
                @input="changePrice"
              />
            </strong>
          </b-col>

          <b-col
            md="6"
            class="mb-1"
          >
            <strong>Other Fees :
              <b-form-input
                v-model.number="item.load.others"
                type="number"
                :disabled="!isEdit"
                placeholder="Other Fees"
                @input="changePrice"
              />
            </strong>
          </b-col>

          <b-col
            md="6"
            class="mb-1"
          >
            <strong>Total :
              <b-form-input
                v-model.number="item.load.expected_total"
                type="number"
                :disabled="!isEdit"
                placeholder="Total"
              />
            </strong>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <hr class="w-100">

    <app-collapse>
      <app-collapse-item title="Operation details">
        <!-- operation details -->
        <b-row>
          <b-col md="12">
            <detail-view-handle
              v-if="item.handles.length > 0"
              :items="item.handles"
              :load-status="item.load.operation_status"
            />
          </b-col>
        </b-row>
      </app-collapse-item>

      <app-collapse-item title="Freight details">
        <b-row class="mt-1">
          <b-col
            md="3"
            class="mb-1"
          >
            <strong>Commodity : {{ item.load.commodity | commodity }}</strong>
          </b-col>

          <b-col
            md="3"
            class="mb-1"
          >
            <strong>Pallets : {{ item.load.pallets }}</strong>
          </b-col>

          <b-col
            md="3"
            class="mb-1"
          >
            <strong>Spots : {{ item.load.spots }}</strong>
          </b-col>

          <b-col
            md="3"
            class="mb-1"
          >
            <strong>Cases : {{ item.load.cases }}</strong>
          </b-col>

          <b-col
            md="3"
            class="mb-1"
          >
            <strong>Weight : {{ item.load.weight }}</strong>
          </b-col>

          <b-col
            md="3"
            class="mb-1"
          >
            <strong>Temperature : {{ item.load.temperature }}</strong>
          </b-col>

          <b-col
            md="3"
            class="mb-1"
          >
            <strong>Reference # : {{ item.operations | reference }}</strong>
          </b-col>

          <b-col
            md="3"
            class="mb-1"
          >
            <strong>Pickup # : {{ item.operations | pickup }}</strong>
          </b-col>

          <b-col
            md="3"
            class="mb-1"
          >
            <strong>Delivery # : {{ item.operations | delivery }}</strong>
          </b-col>
        </b-row>
      </app-collapse-item>

      <app-collapse-item title="Log History">
        <b-row class="mt-1">
          <b-col
            md="12"
            class="mb-1"
          >
            <div
              v-for="(log, index) in item.logs"
              :key="index"
            >
              {{ `${log.firstname} ${log.lastname} ${log.action} ${log.created_at}` }}
            </div>
          </b-col>
        </b-row>
      </app-collapse-item>

      <app-collapse-item title="Documents">
        <b-row class="mt-1">
          <b-col
            md="4"
            class="mb-1"
          >
            <strong>Rate Confirmation Document :
                
              <b-link
                v-if="item.load.rate_conformation"
                :href="item.load.rate_conformation"
                target="_blank"
                class="text-secondary"
              >
                <b-img
                  :src="DocumentIcon"
                  class="iconType"
                />
              </b-link>
            </strong>
          </b-col>

          <b-col
            md="4"
            class="mb-1"
          >
            <strong>Revised Rate Confirmation Document :
                
              <b-link
                v-if="item.load.revived_rate_conformation"
                :href="item.load.revived_rate_conformation"
                target="_blank"
                class="text-secondary"
              >
                <b-img
                  :src="DocumentIcon"
                  class="iconType"
                />
              </b-link>
            </strong>
          </b-col>

          <b-table
            striped
            hover
            responsive
            :items="item.handles"
            :fields="fields"
          >
            <template #cell(freight_image)="row">
              <b-link
                v-if="row.item.freight_image"
                :href="row.item.freight_image"
                target="_blank"
                class="text-secondary"
              >
                <b-img
                  :src="ImageIcon"
                  class="iconType"
                />
              </b-link>
            </template>

            <template #cell(bol)="row">
              <b-link
                v-if="row.item.bol"
                :href="row.item.bol"
                target="_blank"
                class="text-secondary"
              >
                <b-img
                  :src="ImageIcon"
                  class="iconType"
                />
              </b-link>
            </template>

            <template #cell(pod)="row">
              <b-link
                v-if="row.item.pod"
                :href="row.item.pod"
                target="_blank"
                class="text-secondary"
              >
                <b-img
                  :src="ImageIcon"
                  class="iconType"
                />
              </b-link>
            </template>
          </b-table>
        </b-row>
      </app-collapse-item>

      <app-collapse-item title="Pickup and Delivery details">
        <!-- pickup and delivery details -->
        <b-row class="mt-1">
          <b-col md="12">
            <detail-view-operation
              :items="item.operations"
            />
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>

    <!-- <add-google-map /> -->
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, BLink, BBadge, BTable, BFormInput,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as loadService from '@/services/load'
import DetailViewHandle from './DetailViewHandle.vue'
import DetailViewOperation from './DetailViewOperation.vue'
// import AddGoogleMap from './AddGoogleMap.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BBadge,
    BTable,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    // AddGoogleMap,
    // FeatherIcon,
    DetailViewHandle,
    DetailViewOperation,
  },
  filters: {
    commodity(data) {
      const array = data.filter((v, i) => data.indexOf(v) === i)
      return array.join()
    },
    reference(data) {
      const array = data.map(v => v.reference)
      return array.join()
    },
    pickup(data) {
      const array = data.filter(v => v.type === 'pickup')
        .map(item => item.Number)
      return array.join()
    },
    delivery(data) {
      const array = data.filter(v => v.type === 'delivery')
        .map(item => item.Number)
      return array.join()
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      items: [],
      weight: 0,
      pallets: 0,
      cases: 0,
      spots: 0,
      DocumentIcon: require('@/assets/images/upload/document.png'),
      ImageIcon: require('@/assets/images/upload/image.png'),
      collapseType: 'shadow',
      fields: [
        { key: 'type', label: 'Type' },
        { key: 'freight_image', label: 'Freight Image' },
        { key: 'bol', label: 'BOL' },
        { key: 'pod', label: 'POD' },
      ],
      isEdit: false,
    }
  },
  methods: {
    files(data) {
      const array = data.map(item => ({
        type: item.type,
        freight_image: item.freight_image,
        bol: item.bol,
        pod: item.pod,
      }))
      return array.join()
    },

    editMode() {
      this.isEdit = !this.isEdit
    },

    // change and save load price amount
    save() {
      const params = {
        id: this.item.load.id,
        rate_confirmation_amount: this.item.load.rate_confirmation_amount,
        tonu: this.item.load.tonu,
        detention: this.item.load.detention,
        others: this.item.load.others,
        expected_total: this.item.load.expected_total,
      }

      loadService.expectMoney(params)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    changePrice() {
      this.item.load.expected_total = Number(this.item.load.rate_confirmation_amount) + Number(this.item.load.tonu) + Number(this.item.load.detention) + Number(this.item.load.others)
    },
  },
}
</script>

<style lang="scss">
.iconType {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.card-header[aria-expanded="true"] {
  background: #b8c2cc;
  color: white;
}
</style>
