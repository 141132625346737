<template>
  <div>
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :items="items"
      :fields="fields"
    >
      <!-- type -->
      <template #cell(type)="row">
        <b-badge
          :variant="row.item.type === 'pickup' ? 'danger' : 'success'"
          class="px-1 py-1"
        >
          {{ row.item.type }}
        </b-badge>
      </template>

      <!-- startDate -->
      <template #cell(startDate)="row">
        {{ row.item.startDate | datePipe }}
      </template>

      <!-- endDate -->
      <template #cell(endDate)="row">
        {{ row.item.endDate | datePipe }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BBadge } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BTable,
    BBadge,
  },
  filters: {
    datePipe(date) {
      return moment(date).format('MM/DD/YYYY')
    },
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      fields: [
        { key: 'type', label: 'Type', sortable: true },
        { key: 'location', label: 'Location', sortable: true },
        { key: 'city', label: 'City', sortable: true },
        { key: 'zip', label: 'Zip', sortable: true },
        { key: 'state', label: 'State', sortable: true },
        { key: 'startDate', label: 'Start Date', sortable: true },
        { key: 'startTime', label: 'start Time', sortable: true },
        { key: 'endDate', label: 'End Date', sortable: true },
        { key: 'endTime', label: 'End Time', sortable: true },
        { key: 'reference', label: 'Reference', sortable: true },
        { key: 'Number', label: 'Number', sortable: true },
        { key: 'specialNote', label: 'Notes', sortable: true },
        { key: 'seal', label: 'Seal', sortable: true },
        { key: 'weight', label: 'Weight', sortable: true },
        { key: 'pallets', label: 'Pallets', sortable: true },
        { key: 'commodity', label: 'Commodity', sortable: true },
        { key: 'cases', label: 'Cases', sortable: true },
        { key: 'spots', label: 'Spots', sortable: true },
        { key: 'temperature', label: 'Temperature', sortable: true },
        // { key: 'status', label: 'Status', sortable: true },
      ],
    }
  },
}
</script>

<style>

</style>
